import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Collapse,
  Fade,
} from '@mui/material';
import {
  FC,
  useCallback,
  useMemo,
  useState,
  MouseEvent,
  useRef,
  useEffect,
} from 'react';

import ArrowLeft from '@/components/icons/ArrowLeft';
import ArrowRight from '@/components/icons/ArrowRight';
import { mergeSx } from '@/utils';

export interface ICommonButtonProps extends ButtonProps {
  arrowIcon?: 'left' | 'right';
  defaultIsLoading?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => Promise<void> | void;
}

interface CommonButtonComponent extends FC<ICommonButtonProps> {
  Loading: FC<ICommonButtonProps & { isLoading: boolean }>;
}

const LoadingButton: FC<ICommonButtonProps & { isLoading: boolean }> = ({
  children,
  arrowIcon,
  sx,
  fullWidth,
  isLoading,
  disabled,
  ...rest
}) => {
  const arrowContent = useMemo(
    () => !!arrowIcon && !children,
    [arrowIcon, children],
  );
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const defaultWidth = fullWidth ? '100%' : 'fit-content';
  const [width, setWidth] = useState(defaultWidth);
  const isDisabled = disabled || isLoading;

  useEffect(() => {
    if (!buttonRef.current) {
      return;
    }

    setWidth(`${buttonRef.current.offsetWidth}px`);
  }, [arrowIcon, children]);

  return (
    <Button
      ref={buttonRef}
      disableRipple
      disableElevation
      sx={mergeSx(
        {
          px: arrowContent ? 1.5 : 2.5,
          width: isLoading ? width : defaultWidth,
          pl: arrowIcon === 'right' && !isLoading && !arrowContent ? 4 : 3,
          pr: arrowIcon === 'left' && !isLoading && !arrowContent ? 4 : 3,
        },
        sx,
      )}
      disabled={isDisabled}
      {...rest}
    >
      <Collapse
        in={!isLoading}
        orientation="horizontal"
        timeout={{ enter: 500, exit: 250 }}
        unmountOnExit
        mountOnEnter
        onExited={() => {}}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          {arrowIcon === 'left' && (
            <Box
              sx={{
                ml: !arrowContent ? '-4px' : 0,
                mr: !arrowContent ? '8px' : 0,
                width: '24px',
                height: '24px',
              }}
            >
              <ArrowLeft />
            </Box>
          )}
          {children}
          {arrowIcon === 'right' && (
            <Box
              sx={{
                ml: !arrowContent ? '8px' : 0,
                mr: !arrowContent ? '-4px' : 0,
                width: '24px',
                height: '24px',
              }}
            >
              <ArrowRight />
            </Box>
          )}
        </Box>
      </Collapse>
      <Fade
        in={isLoading}
        timeout={{ enter: 500, exit: 0 }}
        unmountOnExit
        mountOnEnter
      >
        <Box
          sx={{
            width: '24px',
            height: '24px',
          }}
        >
          <CircularProgress size={24} color="inherit" sx={{ color: 'white' }} />
        </Box>
      </Fade>
    </Button>
  );
};

const CommonButton: CommonButtonComponent = ({
  onClick,
  defaultIsLoading = false,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);

  const handleClick = useCallback(
    async (e: MouseEvent<HTMLButtonElement>) => {
      if (!onClick) {
        return;
      }

      if (isLoadingRef.current) {
        return;
      }

      isLoadingRef.current = true;

      const timeoutId = setTimeout(() => {
        setIsLoading(true);
      }, 1000);

      try {
        await onClick(e);
      } finally {
        clearTimeout(timeoutId);

        isLoadingRef.current = false;
        setIsLoading(false);
      }
    },
    [onClick],
  );

  return (
    <LoadingButton
      {...rest}
      isLoading={defaultIsLoading || isLoading}
      onClick={handleClick}
    />
  );
};

CommonButton.Loading = LoadingButton;

export default CommonButton;
